import easel from "../images/easel.png";
import eraser from "../images/eraser.png";
import mobileBg from "../images/mobileBg.png";
import pen from "../images/pen.png";
import undo from "../images/undo.png";
import firstRosette from "../images/Host/1stRosette.png";
import secondRosette from "../images/Host/2ndRosette.png";
import thirdRosette from "../images/Host/3rdRosette.png";
import BG from "../images/Host/BG.png";
import Bubble from "../images/Host/Bubble.png";
import ButtonImg from "../images/Host/ButtonImg.png";
import Curtain from "../images/Host/Curtain.png";
import EaselBack from "../images/Host/Easel Back.png";
import EaselFront from "../images/Host/Easel Front.png";
import LeaderboardStrip from "../images/Host/LeaderboardStrip.png";
import Logo from "../images/Host/Logo.png";
import ResultEasel2 from "../images/Host/Result Easel 2.png";
import ResultEasel3 from "../images/Host/Result Easel 3.png";
import ResultEasel4 from "../images/Host/Result Easel 4.png";
import ResultEaselMiddle from "../images/Host/Result Easel Middle.png";
import ResultsStage from "../images/Host/ResultsStage.png";
import RoundCounterChecked from "../images/Host/Round Counter Checked.png";
import RoundCounterUnchecked from "../images/Host/Round Counter Unchecked.png";
import scrawl_fullscreen from "../images/Host/scrawl_fullscreen.png";
import scrawl_help from "../images/Host/scrawl_help.png";
import scrawl_muted from "../images/Host/scrawl_muted.png";
import scrawl_unmuted from "../images/Host/scrawl_unmuted.png";
import Stage from "../images/Host/Stage.png";
import Stool from "../images/Host/Stool.png";
import TransparentBubble from "../images/Host/Transparent Bubble.png";



const images = [
    easel,
    eraser,
    mobileBg,
    pen,
    undo,
    firstRosette,
    secondRosette,
    thirdRosette,
    BG,
    Bubble,
    ButtonImg,
    Curtain,
    EaselBack,
    EaselFront,
    LeaderboardStrip,
    Logo,
    ResultEasel2,
    ResultEasel3,
    ResultEasel4,
    ResultEaselMiddle,
    ResultsStage,
    RoundCounterChecked,
    RoundCounterUnchecked,
    scrawl_fullscreen,
    scrawl_help,
    scrawl_muted,
    scrawl_unmuted,
    Stage,
    Stool,
    TransparentBubble,
];

const fonts = [
    { font: "KCLisaHand", path: `https://${window.location.host}/fonts/KCLisaHand.otf` },
    { font: "Remachine", path: `https://${window.location.host}/fonts/RemachineScript.ttf` },
    { font: "Resistenza", path: `https://${window.location.host}/fonts/Resistenza-PeperoncinoSans.otf` },
    { font: "Helenita", path: `https://${window.location.host}/fonts/HelenitaBook.otf` },
    { font: "JenWagner", path: `https://${window.location.host}/fonts/JenWagnerCo.-PalmerLakePrintRegular.otf` },
];

export default {
    images,
    fonts,
};