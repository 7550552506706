import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';


import Stage from "components/Stage";

import getAvatarById from "constants/avatars";


//import DaveRevealAnim from "animations/tutorial/Dave reveal.js";

import BGMusic from "audio/DrawWithDaveMusic.wav";
import CorrectGuessSFX from "audio/CorrectGuess.wav";
import DaveImageRevealSFX from "audio/DaveImageReveal.wav";
import EndingSFX from "audio/Ending.wav";
import PointSoundSFX from "audio/PointSound.wav";
import RoundCompleteSFX from "audio/RoundComplete.mp3";
import WrongGuessSFX from "audio/WrongGuess.wav";
import Timer5SecondsClockSFX from "audio/timer-5-seconds-clock.mp3";
import DrumRollSFX from "audio/drum-roll.wav";

import "animate.css";
import styles from 'components/TutorialStyles.module.scss';
import DaveSpeechBubble from '../animations/tutorial/Dave Speech Bubble';
import ThisIsDave from '../animations/tutorial/This is Dave.js';
import House from '../animations/tutorial/House.js';
import DaveReveal from "../animations/tutorial/Dave reveal.js";

import DaveScreen from "../images/Tutorial/Tutorial_Dave Screen.png";
import Player1Screen from "../images/Tutorial/Tutorial_Player 1 Screen.png";
import Player2Screen from "../images/Tutorial/Tutorial_Player 2 Screen.png";
import Player3Screen from "../images/Tutorial/Tutorial_Player 3 Screen.png";
import Drawing1 from "../images/Tutorial/drawing1.png";
import Drawing2 from "../images/Tutorial/drawing2.png";
import Drawing3 from "../images/Tutorial/drawing3.png";


var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    CorrectGuess: {
        import: CorrectGuessSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DaveImageReveal: {
        import: DaveImageRevealSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Ending: {
        import: EndingSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PointSound: {
        import: PointSoundSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    RoundComplete: {
        import: RoundCompleteSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    WrongGuess: {
        import: WrongGuessSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5SecondsClock: {
        import: Timer5SecondsClockSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};


export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: true,
            showStepOneTextOne: false,
            showStepOneTextTwo: false,
            stepOneTextOne: "Take a deep breath and relax...",
            stepOneTextTwo: "...it's time to Draw along with Dave \n ",
            daveRevealSegments: [0, 60],
            stepOnePlayers: [
                {
                    avatar: 2,
                    show: false,
                },
                {
                    avatar: 8,
                    show: false,
                },
                {
                    avatar: 10,
                    show: false,
                    isDave: true,
                    move: false,
                    showLabel: false,
                },
                {
                    avatar: 6,
                    show: false,
                }
            ],

            showStepTwo: true,
            showStepTwoText: false,
            showStepTwoTextOne: false,
            showStepTwoTextTwo: false,
            showStepTwoDave: false,
            showStepTwoPhone: false,
            playStepTwoPhoneAnim: false,
            playStepTwoBubble: false,

            showStepThree: true,
            showStepThreeText: false,
            stepThreePlayers: [
                {
                    avatar: 2,
                    drawingImg: Player1Screen,
                    show: false,
                },
                {
                    avatar: 8,
                    drawingImg: Player2Screen,
                    show: false,
                },
                {
                    avatar: 6,
                    drawingImg: Player3Screen,
                    show: false,
                }
            ],

            showStepFour: true,
            showStepFourTextOne: false,
            showStepFourTextTwo: false,
            stepFourTextTwo: "They can guess straight away..",
            showStepFourStage: false,
            showStepFourAnswers: false,
            stepFourPlayers: [
                {
                    avatar: 2,
                    drawingImg: "",
                    hide: true,
                    drawWithData: {
                        drawingURL: Drawing1,
                        currentGuess: "A House"
                    }
                },
                {
                    avatar: 8,
                    drawingImg: "",
                    hide: true,
                    drawWithData: {
                        drawingURL: Drawing2,
                        currentGuess: "A Party Hat"
                    }
                },
                {
                    avatar: 6,
                    drawingImg: "",
                    hide: true,
                    drawWithData: {
                        drawingURL: Drawing3,
                        currentGuess: "A Robot"
                    }
                }
            ],


            showStepFive: true,
            showStepFiveText: false,
        };
        this.daveRevealAnimRef = React.createRef();
    }

    componentDidMount() {
        this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    doStepOne() {
        this.setState({ showStepOne: true, showStepOneTextOne: true, });
        setTimeout(() => {
            let playerTime = 0;
            let stepOnePlayers = [...this.state.stepOnePlayers];
            stepOnePlayers.forEach((x, i) => {
                setTimeout(() => {
                    stepOnePlayers[i].show = true;
                    this.setState({ stepOnePlayers, });
                    playerTime += (i * 200);
                }, (i * 200));
            });

            setTimeout(() => {
                this.setState({ showStepOneTextTwo: true });

                setTimeout(() => {
                    this.setState({ showStepOneTextTwo: false, showStepOneTextOne: false, });
                    setTimeout(() => {
                        this.setState({ stepOneTextOne: "Each round, one of you will be Dave", showStepOneTextOne: true, });
                    }, 750);

                    setTimeout(() => {
                        stepOnePlayers = [...this.state.stepOnePlayers];
                        //stepOnePlayers[2].showDave = true;
                        this.doSecondDaveRevealLoop();

                        setTimeout(() => {
                            this.doThirdDaveRevealLoop()
                        }, 500);
                        this.setState({ stepOnePlayers });

                        setTimeout(() => {
                            stepOnePlayers = [...this.state.stepOnePlayers];
                            stepOnePlayers[0].show = false;
                            stepOnePlayers[1].show = false;
                            stepOnePlayers[2].move = true;
                            stepOnePlayers[2].showLabel = true;
                            stepOnePlayers[3].show = false;
                            this.setState({ stepOnePlayers });

                            setTimeout(() => {
                                this.setState({ stepOneTextTwo: "You'll be drawing a lovely picture for everyone. \nThey'll be trying to draw along with you.", showStepOneTextTwo: true, });

                                setTimeout(() => {
                                    this.setState({ showStepOne: false, });
                                    setTimeout(() => {
                                        this.doStepTwo();
                                    }, 1000);
                                }, 3000);
                            }, 2000);
                        }, 2000);
                    }, 2000);
                }, 3000);
            }, (2000 + playerTime));
        }, 1000);
    }

    doStepTwo() {
        this.setState({ showStepTwo: true, });
        setTimeout(() => {
            this.setState({ showStepTwoTextOne: true, showStepTwoDave: true, showStepTwoPhone: true, });

            setTimeout(() => {
                this.setState({ showStepTwoTextTwo: true, });

                setTimeout(() => {
                    this.setState({ playStepTwoBubble: true, playStepTwoPhoneAnim: true });

                    setTimeout(() => {
                        this.setState({ showStepTwo: false });
                        this.doStepThree();
                    }, 15000);
                }, 2000);
            }, 4000);
        }, 1000);
    }

    changeStepOneText(newText) {
        this.setState({ showStepOneTextOne: false });
        setTimeout(() => {
            this.setState({ stepOneTextOne: newText, showStepOneTextOne: true, });
        }, 750);
    }

    doStepThree() {
        this.setState({ showStepThree: true, });
        setTimeout(() => {
            this.setState({ showStepThreeText: true, });
            setTimeout(() => {
                let playerTime = 0;
                let stepThreePlayers = [...this.state.stepThreePlayers];
                stepThreePlayers.forEach((x, i) => {
                    setTimeout(() => {
                        stepThreePlayers[i].show = true;
                        this.setState({ stepThreePlayers, });
                        playerTime += (i * 200);
                    }, (i * 200));
                });
                setTimeout(() => {
                    this.setState({ showStepThree: false, });
                    this.doStepFour();
                }, (5000 + playerTime));
            }, 1500);
        }, 1000);
    }

    doStepFour() {
        this.setState({ showStepFour: true, });
        setTimeout(() => {
            let stepFourPlayers = [...this.state.stepFourPlayers];
            stepFourPlayers[1].hide = false;
            this.setState({ showStepFourTextOne: true, showStepFourStage: true, showStepFourTextTwo: true, stepFourPlayers, });

            setTimeout(() => {
                this.setState({ showStepFourAnswers: true });

                setTimeout(() => {
                    this.setState({ showStepFourTextTwo: false, showStepFourAnswers: false, });
                    setTimeout(() => {
                        this.setState({ showStepFourTextTwo: true, stepFourTextTwo: "Or they can wait to see everyone else's drawings first" });
                        let stepFourPlayers = [...this.state.stepFourPlayers];
                        stepFourPlayers[0].hide = false;
                        stepFourPlayers[1].drawWithData.currentGuess = "A House";
                        stepFourPlayers[2].hide = false;

                        setTimeout(() => {
                            this.setState({ showStepFourAnswers: true, });

                            setTimeout(() => {
                                this.setState({ showStepFour: false, });
                                this.doStepFive();
                            }, 4000);
                        }, 3000);
                    }, 750);

                }, 3000);
            }, 3000);

        }, 1000);
    }

    doStepFive() {
        this.setState({ showStepFive: true, });
        setTimeout(() => {
            this.setState({ showStepFiveText: true, });

            setTimeout(() => {
                this.setState({ showStepFive: false, });
                this.props.room.send("end_tutorial", {});
            }, 4000);
        }, 1000);
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    getStage() {
        let variationNum = 1;
        switch (this.state.stepFourPlayers.length) {
            case 2:
                variationNum = 1;
                break;
            case 3:
                variationNum = 2;
                break;
            case 4:
                variationNum = 3;
                break;
            case 5:
                variationNum = 4;
                break;
            case 6:
                variationNum = 5;
                break;
            case 7:
                variationNum = 6;
                break;
        }
        return <Stage variation={variationNum} players={this.state.stepFourPlayers} showAnswers={this.state.showStepFourAnswers} />
    }

    doFirstDaveRevealLoop = () => {
        console.log("do dave reveal loop one!");
        const { anim } = this.daveRevealAnimRef.current;
        this.setState({ daveRevealSegments: [0, 60] });
        anim.playSegments([0, 60], true);
    }

    doSecondDaveRevealLoop = () => {
        console.log("do dave reveal loop two!");
        const { anim } = this.daveRevealAnimRef.current;
        this.setState({ daveRevealSegments: [60, 75] });
        if (anim) anim.playSegments([60, 75], true);
    }

    doThirdDaveRevealLoop = () => {
        console.log("do dave reveal loop three!");
        const { anim } = this.daveRevealAnimRef.current;
        this.setState({ daveRevealSegments: [75, 135] });
        if (anim) anim.playSegments([75, 135], true);
    }

    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneTextOne && styles.show}`}>{this.state.stepOneTextOne}</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.player} ${x.show && styles.show} ${x.move && styles.move}`}>
                                    {
                                        !x.isDave &&
                                        <Lottie
                                            options={getAvatarById(x.avatar).idleAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                    }
                                    {
                                        x.isDave &&
                                        <React.Fragment>
                                            <div className={styles.dave}>
                                                <Lottie
                                                    options={DaveReveal}
                                                    width="100%"
                                                    height="100%"
                                                    eventListeners={[
                                                        {
                                                            eventName: 'DOMLoaded',
                                                            callback: () => this.doFirstDaveRevealLoop()
                                                        },
                                                    ]}
                                                    segments={this.state.daveRevealSegments}
                                                    isClickToPauseDisabled={true}
                                                        ref={this.daveRevealAnimRef}
                                                />
                                            </div>
                                            {
                                                x.showLabel &&
                                                <div className={styles.daveLabel}>
                                                    <Lottie
                                                        options={ThisIsDave}
                                                        width="100%"
                                                        height="100%"
                                                        isClickToPauseDisabled={true}
                                                    />
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepOneTextTwo && styles.show}`}>{this.state.stepOneTextTwo}</div>
                </div>
                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`} style={{ width: "100%" }}>
                    <div className={styles.rowBox}>
                        <div className={styles.daveSection}>
                            <div className={`${styles.dave} ${this.state.showStepTwoDave && styles.show}`}>
                                <Lottie
                                    options={getAvatarById(10).dave}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                            <div className={`${styles.bubble}`}>
                                {
                                    this.state.playStepTwoBubble &&
                                    <Lottie
                                        options={DaveSpeechBubble}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                }
                            </div>
                        </div>
                        <div className={styles.phoneSection}>
                            <div className={`${styles.phone} ${this.state.showStepTwoPhone && styles.show}`}>
                                {
                                    this.state.playStepTwoPhoneAnim &&
                                    <Lottie
                                        options={House}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                }
                            </div>
                        </div>
                        <div className={styles.textSection}>
                            <div className={`${styles.text} ${this.state.showStepTwoTextOne && styles.show}`} style={{ marginBottom: 0, }}>Dave, you'll get a prompt.<br />'A House'</div>
                            <div className={`${styles.text} ${this.state.showStepTwoTextTwo && styles.show}`} style={{ marginBottom: 0, }}>Draw this picture on your phone, LOUDLY describing everything as you go.</div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepThreeText && styles.show}`} style={{ marginLeft: "auto", marginRight: "auto", width: "90%", }}>If you're Dave, it's VERY IMPORTANT to describe everything you draw.<br/>This lets the other players draw along on their phones.</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepThreePlayers.map((x) => {
                                return <div className={`${styles.playerBox} ${x.show && styles.show}`}>
                                    <div className={styles.avatar}>
                                        <Lottie
                                            options={getAvatarById(x.avatar).idleAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                    <img className={styles.phone} src={x.drawingImg} />
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`} style={{width: "100%", height: "100%", justifyContent: 'center', }}>
                    <div className={`${styles.text} ${this.state.showStepFourTextOne && styles.show}`} style={{ marginBottom: 0,}}>When Dave's finished, players get a choice</div>
                    <div className={`${styles.text} ${this.state.showStepFourTextTwo && styles.show}`} style={{ marginBottom: "45vh", marginTop: 0, }}>{this.state.stepFourTextTwo}</div>
                    <div className={`${styles.stageSection} ${this.state.showStepFourStage && styles.show}`}>
                        {
                            this.getStage()
                        }
                    </div>
                </div>
                <div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepFiveText && styles.show}`} style={{ marginBottom: 0, }}>GOOD LUCK!</div>
                   
                </div>
            </div>
        );
    }
}