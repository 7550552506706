import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";


export default class Player extends Component {

    constructor(props) {
        super(props);
        let fontSize = this.getFontSize(props.player);
        this.state = {
            fontSize: fontSize,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ fontSize: this.getFontSize(nextProps.player), });
    }


    getFontSize() {
        if (this.props.player) {
            let text = this.props.player.drawWithData.currentGuess || "";
            let fontSize = "";

            if (text.length <= 10) {
                fontSize = "2.75vh";
            } else if (text.length <= 20) {
                fontSize = "2.25vh";
            } else {
                fontSize = "1.75";
            }
            return fontSize;
        } else {
            return "2.25vh";
        }
    }

    render() {
        return (
            <div id={`player-${this.props.player.id}`} className={`${styles.player} ${this.props.left ? "" : styles.reverse}`}>
                <div
                    id={`potato-${this.props.player.id}`}
                    className={`${styles.potato}`}
                >
                    <Lottie
                        options={this.props.davePlayer && this.props.davePlayer.id == this.props.player.id ? getAvatarById(this.props.player.avatar).dave : getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={`${styles.textSection}`}>
                    <div className={`${styles.name}`}>{this.props.player.name}</div>
                    <div className={`${styles.box}  ${this.props.player.drawWithData.currentGuess.length > 0 && styles.show}`} style={{fontSize: `${this.getFontSize()}`}}>{this.props.player.drawWithData.currentGuess}</div>
                    <div className={`${styles.points}`}>Points {this.props.player.drawWithData.score}</div>
                </div>
            </div>
        )
    }
}