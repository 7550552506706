import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/StageStyles.module.scss";

import stageImg from "images/Host/Stage.png";
import easel1 from "images/Host/Result Easel Middle.png";
import easel2 from "images/Host/Result Easel 2.png";
import easel3 from "images/Host/Result Easel 4.png";
import easel4 from "images/Host/Result Easel 3.png";
import BubbleImg from "images/Host/Transparent Bubble.png";


export default class Stage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillReceiveProps(nextProps) {

    }

    getPositionByIndexAndVariation(index) {
        switch (this.props.variation) {
            case 1:
                switch (index) {
                    case 0:
                        return 2;
                    case 1:
                        return 5;
                }
                break;
            case 2:
                switch (index) {
                    case 0:
                        return 3;
                    case 1:
                        return 1;
                    case 2:
                        return 6;
                }
                break;
            case 3:
                switch (index) {
                    case 0:
                        return 4;
                    case 1:
                        return 2;
                    case 2:
                        return 5;
                    case 3:
                        return 7;
                }
                break;
            case 4:
                switch (index) {
                    case 0:
                        return 2;
                    case 1:
                        return 5;
                    case 2:
                        return 3;
                    case 3:
                        return 1;
                    case 4:
                        return 6;
                }
                break;
            case 5:
                switch (index) {
                    case 0:
                        return 3;
                    case 1:
                        return 1;
                    case 2:
                        return 6;
                    case 3:
                        return 3;
                    case 4:
                        return 1;
                    case 5:
                        return 6;
                }
                break;
            case 6:
                switch (index) {
                    case 0:
                        return 3;
                    case 1:
                        return 1;
                    case 2:
                        return 6;
                    case 3:
                        return 4;
                    case 4:
                        return 2;
                    case 5:
                        return 5;
                    case 6:
                        return 7;
                }
                break;
        }
    }

    getSkewByPosition(position) {
        switch (position) {
            case 1:
                return 0;
            case 2:
                return -3;
            case 3:
                return -8;
            case 4:
                return -8.5;
            case 5:
                return 5;
            case 6:
                return 8;
            case 7:
                return 8.5;
        }
    }

    getEaselImgByPosition(position) {
        switch (position) {
            case 1:
                return easel1;
            case 2:
            case 5:
                return easel2;
            case 3:
            case 6:
                return easel3;
            case 4:
            case 7:
                return easel4;
        }
    }

    getStagePlayer(player, index) {
        const position = this.getPositionByIndexAndVariation(index);
        return <div className={`${styles.stagePlayer} ${styles[`position-${position}`]} ${player.hide && styles.hide}`}>
            <div className={styles.easelSection}>
                <img className={`${styles.easelImage}`} style={{ transform: `scaleX(${position > 4 ? 1 : -1})` }} src={this.getEaselImgByPosition(position)} />
                <img className={`${styles.drawingImage}`} style={{transform: `skewY(${this.getSkewByPosition(position)}deg)`}} src={player.drawWithData.drawingURL} />
            </div>
            <div className={styles.potato}>
                <Lottie
                    options={getAvatarById(player.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div>
            <div className={`${styles.answerSection} ${this.props.showAnswers && styles.show}`}>
                <div className={styles.bubbleBox}>
                    <img src={BubbleImg} className={`${styles.fakeBubble}`} />
                    <div src={BubbleImg} className={`${styles.bubbleImg} ${player.drawWithData.showValidation ? player.drawWithData.isCorrect ? styles.correct : styles.wrong : ""}`} />
                </div>
                <div className={`${styles.answerText}`} style={{ fontSize: this.getFontSize(player) }}>{player.drawWithData.currentGuess}</div>
            </div>
        </div>
    }

    getFontSize(player) {
        if (player) {
            let name = player.drawWithData.currentGuess || "";
            let fontSize = "";

            if (name.length <= 10) {
                fontSize = "3vh";
            } else if (name.length <= 20) {
                fontSize = "2.5vh";
            } else {
                fontSize = "2vh";
            }
            return fontSize;
        } else {
            return "2.5vh";
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className={`${styles.stage} ${styles[`variation-${this.props.variation}`]} ${styles.front}`}>
                    <img src={stageImg} className={styles.stageImg} />
                    {
                        this.props.players.map((x, index) => {
                            switch (this.props.variation) {
                                case 1:
                                case 2:
                                case 3:
                                    return this.getStagePlayer(x, index);
                                    break;
                                case 4:
                                    if ([2, 3, 4].includes(index)) {
                                        return this.getStagePlayer(x, index);
                                    }
                                    break;
                                case 5:
                                    if ([3, 4, 5].includes(index)) {
                                        return this.getStagePlayer(x, index);
                                    }
                                    break;
                                case 6:
                                    if ([3, 4, 5, 6].includes(index)) {
                                        return this.getStagePlayer(x, index);
                                    }
                                    break;
                            }
                        })
                    }
                </div>
                {
                    this.props.variation >= 4 &&
                    <div className={`${styles.stage} ${styles[`variation-${this.props.variation}`]} ${styles.back}`}>
                        <img src={stageImg} className={styles.stageImg} />
                        {
                            this.props.players.map((x, index) => {
                                switch (this.props.variation) {
                                    case 4:
                                        if ([0, 1].includes(index)) {
                                            return this.getStagePlayer(x, index);
                                        }
                                        break;
                                    case 5:
                                    case 6:
                                        if ([0, 1, 2].includes(index)) {
                                            return this.getStagePlayer(x, index);
                                        }
                                        break;
                                }
                            })
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}