import potato0 from "images/Avatars/potato-0.png";
import potato1 from "images/Avatars/potato-1.png";
import potato2 from "images/Avatars/potato-2.png";
import potato3 from "images/Avatars/potato-3.png";
import potato4 from "images/Avatars/potato-4.png";
import potato5 from "images/Avatars/potato-5.png";
import potato6 from "images/Avatars/potato-6.png";
import potato7 from "images/Avatars/potato-7.png";
import potato8 from "images/Avatars/potato-8.png";
import potato9 from "images/Avatars/potato-9.png";
import potato10 from "images/Avatars/potato-10.png";
import potato11 from "images/Avatars/potato-11.png";
import potato12 from "images/Avatars/potato-12.png";
import potato13 from "images/Avatars/potato-13.png";
import potato14 from "images/Avatars/potato-14.png";
import potato15 from "images/Avatars/potato-15.png";
import potato16 from "images/Avatars/potato-16.png";
import potato17 from "images/Avatars/potato-17.png";
import potato18 from "images/Avatars/potato-18.png";
import potato19 from "images/Avatars/potato-19.png";
import potato20 from "images/Avatars/potato-20.png";
import potato21 from "images/Avatars/potato-21.png";
import potato22 from "images/Avatars/potato-22.png";
import potato23 from "images/Avatars/potato-23.png";
import potato24 from "images/Avatars/potato-24.png";

import potato0Anim from "animations/avatars/potato-0.json";
import potato1Anim from "animations/avatars/potato-1.json";
import potato2Anim from "animations/avatars/potato-2.json";
import potato3Anim from "animations/avatars/potato-3.json";
import potato4Anim from "animations/avatars/potato-4.json";
import potato5Anim from "animations/avatars/potato-5.json";
import potato6Anim from "animations/avatars/potato-6.json";
import potato7Anim from "animations/avatars/potato-7.json";
import potato8Anim from "animations/avatars/potato-8.json";
import potato9Anim from "animations/avatars/potato-9.json";
import potato10Anim from "animations/avatars/potato-10.json";
import potato11Anim from "animations/avatars/potato-11.json";
import potato12Anim from "animations/avatars/potato-12.json";
import potato13Anim from "animations/avatars/potato-13.json";
import potato14Anim from "animations/avatars/potato-14.json";
import potato15Anim from "animations/avatars/potato-15.json";
import potato16Anim from "animations/avatars/potato-16.json";
import potato17Anim from "animations/avatars/potato-17.json";
import potato18Anim from "animations/avatars/potato-18.json";
import potato19Anim from "animations/avatars/potato-19.json";
import potato20Anim from "animations/avatars/potato-20.json";
import potato21Anim from "animations/avatars/potato-21.json";
import potato22Anim from "animations/avatars/potato-22.json";
import potato23Anim from "animations/avatars/potato-23.json";
import potato24Anim from "animations/avatars/potato-24.json";

import dave0Anim from "animations/dave/dave-0.json";
import dave1Anim from "animations/dave/dave-1.json";
import dave2Anim from "animations/dave/dave-2.json";
import dave3Anim from "animations/dave/dave-3.json";
import dave4Anim from "animations/dave/dave-4.json";
import dave5Anim from "animations/dave/dave-5.json";
import dave6Anim from "animations/dave/dave-6.json";
import dave7Anim from "animations/dave/dave-7.json";
import dave8Anim from "animations/dave/dave-8.json";
import dave9Anim from "animations/dave/dave-9.json";
import dave10Anim from "animations/dave/dave-10.json";
import dave11Anim from "animations/dave/dave-11.json";
import dave12Anim from "animations/dave/dave-12.json";
import dave13Anim from "animations/dave/dave-13.json";
import dave14Anim from "animations/dave/dave-14.json";
import dave15Anim from "animations/dave/dave-15.json";
import dave16Anim from "animations/dave/dave-16.json";
import dave17Anim from "animations/dave/dave-17.json";
import dave18Anim from "animations/dave/dave-18.json";
import dave19Anim from "animations/dave/dave-19.json";
import dave20Anim from "animations/dave/dave-20.json";
import dave21Anim from "animations/dave/dave-21.json";
import dave22Anim from "animations/dave/dave-22.json";
import dave23Anim from "animations/dave/dave-23.json";
import dave24Anim from "animations/dave/dave-24.json";

function getOptions(animData) {
    return {
        loop: true,
        autoplay: true,
        prerender: true,
        renderer: "svg",
        animationData: animData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid'
        },
    }
}

function getAvatarById(avId) {
    return avatars.find(x => x.id == avId);
}

const avatars = [
    { id: 0, src: potato0, idleAnim: getOptions(potato0Anim), dave: getOptions(dave0Anim)},
    { id: 1, src: potato1, idleAnim: getOptions(potato1Anim), dave: getOptions(dave1Anim)},
    { id: 2, src: potato2, idleAnim: getOptions(potato2Anim), dave: getOptions(dave2Anim)},
    { id: 3, src: potato3, idleAnim: getOptions(potato3Anim), dave: getOptions(dave3Anim)},
    { id: 4, src: potato4, idleAnim: getOptions(potato4Anim), dave: getOptions(dave4Anim)},
    { id: 5, src: potato5, idleAnim: getOptions(potato5Anim), dave: getOptions(dave5Anim)},
    { id: 6, src: potato6, idleAnim: getOptions(potato6Anim), dave: getOptions(dave6Anim)},
    { id: 7, src: potato7, idleAnim: getOptions(potato7Anim), dave: getOptions(dave7Anim)},
    { id: 8, src: potato8, idleAnim: getOptions(potato8Anim), dave: getOptions(dave8Anim)},
    { id: 9, src: potato9, idleAnim: getOptions(potato9Anim), dave: getOptions(dave9Anim)},
    { id: 10, src: potato10, idleAnim: getOptions(potato10Anim), dave: getOptions(dave10Anim)},
    { id: 11, src: potato11, idleAnim: getOptions(potato11Anim), dave: getOptions(dave11Anim)},
    { id: 12, src: potato12, idleAnim: getOptions(potato12Anim), dave: getOptions(dave12Anim)},
    { id: 13, src: potato13, idleAnim: getOptions(potato13Anim), dave: getOptions(dave13Anim)},
    { id: 14, src: potato14, idleAnim: getOptions(potato14Anim), dave: getOptions(dave14Anim)},
    { id: 15, src: potato15, idleAnim: getOptions(potato15Anim), dave: getOptions(dave15Anim)},
    { id: 16, src: potato16, idleAnim: getOptions(potato16Anim), dave: getOptions(dave16Anim)},
    { id: 17, src: potato17, idleAnim: getOptions(potato17Anim), dave: getOptions(dave17Anim)},
    { id: 18, src: potato18, idleAnim: getOptions(potato18Anim), dave: getOptions(dave18Anim)},
    { id: 19, src: potato19, idleAnim: getOptions(potato19Anim), dave: getOptions(dave19Anim)},
    { id: 20, src: potato20, idleAnim: getOptions(potato20Anim), dave: getOptions(dave20Anim)},
    { id: 21, src: potato21, idleAnim: getOptions(potato21Anim), dave: getOptions(dave21Anim)},
    { id: 22, src: potato22, idleAnim: getOptions(potato22Anim), dave: getOptions(dave22Anim)},
    { id: 23, src: potato23, idleAnim: getOptions(potato23Anim), dave: getOptions(dave23Anim)},
    { id: 24, src: potato24, idleAnim: getOptions(potato24Anim), dave: getOptions(dave24Anim)},
];

export default getAvatarById;