import React, { Component } from 'react';
import { Route } from 'react-router';
import { ReactSketchCanvas } from 'react-sketch-canvas';

import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import DefaultView from "components/DefaultView";

import styles from 'components/ClientStyles.module.scss';

import easel from 'images/easel.png';

import EraserImg from 'images/eraser.png';
import PenImg from 'images/pen.png';
import UndoImg from 'images/undo.png';

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    DaveDrawing: "dave_drawing",
    FinalDrawing: "final_drawing",
    EarlyGuessing: "early_guessing",
    FinalGuessing: "final_guessing",
    DaveChoosing: "dave_choosing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "draw_with";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            canvasWidth: 0,
            reconnectionToken: "",
            gameState: GameStates.Loading,

            player: null,
           
            showDefaultView: true,
            doingTutorial: false,

            isDave: false,
            showDrawingSection: false,
            isSubmitting: false,
            drawStep1: true,
            currentQuestion: "",
            showGuessSection: false,
            drawingURL: "",
            isEarlyGuess: false,
            currentGuess: "",
            hasGuessed: false,
            showChoosingSection: false,
            players: [],
            choiceList: [],

            penSizes: [
                4,
                8,
                12,
                16,
                20,
                24,
            ],

            currenPenSize: 4,
            eraseMode: false,
            showPens: false,
            currentPaths: [],

            gotLocationPing: true,
            connectionIssue: false,
            hostConnected: false,
        };
        this.locationCheckInterval = null;
        this.canvas = React.createRef();
    }

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);

        let width = window.screen.width;
        console.log("screen width: " + width);
        if (width > 600) {
            width = 600;
        }
        this.setState({ canvasWidth: width - 40 });
    }

     componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    toggleErase = () => {
        let value = this.state.eraseMode ? false : true;
        this.setState({ eraseMode: value, });
        this.canvas.current.eraseMode(value);
    }

    undoDraw = () => {
        this.canvas.current.undo();
    }

    redoDraw = () => {
        this.canvas.current.redo();
    }

    handlePenSizeChange = (size) => {
        this.setState({ currenPenSize: size, showPens: false, });
    }

    showPens = () => {
        let value = this.state.showPens ? false : true;
        this.setState({ showPens: value, });
    }

    async storePaths() {
        if (this.canvas.current) {
            let paths = await this.canvas.current.exportPaths().then(data => {
                return data;
            });
            if (paths.length > this.state.currentPaths.length) {
                this.setState({ currentPaths: paths, });

                console.log("Storing Paths in local storage : ", paths);
                localStorage.setItem(`${this.state.myId}-CanvasPaths`, JSON.stringify(paths));
            }
        }
    }


    // update this with your gamestates, if player joins/reconnects mid-game
    async doCatchup(state) {
        const player = state.players[this.state.myId];
        switch (state.drawWithData.gameState) {
            case GameStates.Tutorial:
                if (!player.votedSkip) {
                    this.setState({ doingTutorial: true, showDefaultView: false, });
                } else {
                    this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                }
                break;
            case GameStates.DaveDrawing:
                if (player.drawWithData.isDave) {
                    if (!player.drawWithData.hasSubmitted) {
                        this.state.room.send("request_question", {});
                    } else {
                        this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                    }
                } else {
                    if (!this.state.showDrawingSection) {
                        await this.startDrawingRound({ question: "" });
                    }
                }
                break;
            case GameStates.FinalDrawing:
                if (!player.drawWithData.isDave && !player.drawWithData.hasSubmitted) {
                    this.setState({ showDrawingSection: true, showDefaultView: false, });
                } else {
                    this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                }
                break;
            case GameStates.EarlyGuessing:
                if (!player.drawWithData.isDave && !player.drawWithData.ready) {
                    this.setState({ showGuessSection: true, isEarlyGuess: true, showDefaultView: false, });
                } else {
                    this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                }
                break;
            case GameStates.FinalGuessing:
                if (!player.drawWithData.isDave && !player.drawWithData.hasGuessed) {
                    this.setState({ showGuessSection: true, isEarlyGuess: false, showDefaultView: false, });
                } else {
                    this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                }
                break;
            case GameStates.DaveChoosing:
                if (player.drawWithData.isDave) {
                    if (!player.drawWithData.ready && !this.state.showChoosingSection) {
                        this.setState({ showDefaultView: false, showChoosingSection: true, choiceList: player.drawWithData.correctPlayerIds, });
                    } else if (player.drawWithData.ready) {
                        this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                    }
                } else if (!player.drawWithData.isDave) {
                this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                }
                break;
            default:
                //this.setState({ showDefaultView: true, showChoosingSection: false, showGuessSection: false, showDrawingSection: false, doingTutorial: false, });
                break;
        }
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false, showDefaultView: true, });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    submitDrawing = () => {
        this.setState({ isSubmitting: true, });
        this.canvas.current.exportImage("png").then(url => {
            if (this.drawinginterval) {
                clearInterval(this.drawinginterval);
                this.drawinginterval = null;
            }
            if (this.state.isDave) {
                this.state.room.send("dave_submit_drawing", { drawingURL: url, });
            } else {
                this.state.room.send("submit_drawing", { drawingURL: url, });
            }
            localStorage.removeItem(`${this.state.myId}-CanvasPaths`);
            this.setState({ showDrawingSection: false, showDefaultView: true, isSubmitting: false, });
        });
    }

    clearCanvas = () => {
        this.canvas.current.clearCanvas();
    }

    updateGuess = (e) => {
        this.setState({ currentGuess: e.target.value, });
        this.state.room.send("update_guess", { guess: e.target.value, });
    }

    submitGuess = () => {
        if (this.state.currentGuess.length > 0) {
            this.state.room.send("submit_guess", { guess: this.state.currentGuess, });
            this.setState({ showGuessSection: false, isEarlyGuess: false, showDefaultView: true, hasGuessed: true, currentGuess: "", });
        }
    }

    guessLater = () => {
        this.state.room.send("guess_later", {});
        this.setState({ showGuessSection: false, isEarlyGuess: false, showDefaultView: true, });
    }

    getArrayOfPlayers(players) {
        console.log("get arr of players");
        let arrPlayers = [];
        //for (const [key, value] of Object.entries(players)) {
        //    console.log(value);
        //    if (!value.drawWithData.isDave) arrPlayers.push(value);
        //}
        players.forEach((value, key) => {
            if (!value.drawWithData.isDave) arrPlayers.push(value);
        });
        return arrPlayers;
    }

    selectChoice(playerId) {
        if (this.state.choiceList.includes(playerId)) {
            let stateChoiceList = [...this.state.choiceList];
            let index = stateChoiceList.indexOf(playerId);
            if (index !== -1) {
                stateChoiceList.splice(index, 1);
                this.setState({ choiceList: stateChoiceList });
            }
        } else {
            this.setState((prevState) => ({
                choiceList: [...prevState.choiceList, playerId]
            }));
        }
        this.state.room.send("update_choices", { choices: this.state.choiceList });
    }

    submitChoices = () => {
        this.state.room.send("submit_choices", { choices: this.state.choiceList, });
        this.setState({ showChoosingSection: false, showDefaultView: true, choiceList: [], });
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    async startDrawingRound(message,) {
        if (!this.drawingInterval) {
            this.drawingInterval = setInterval(async () => {
                await this.storePaths();
            }, 1000);
        }
        this.setState({
            showDrawingSection: true,
            showDefaultView: false,
            currentQuestion: message.question,
        });

        let paths = localStorage.getItem(`${this.state.myId}-CanvasPaths`);
        if (paths != null) {
            paths = JSON.parse(paths);

            if (this.canvas.current) {
                this.canvas.current.loadPaths(paths);
            }
        }
    }

    clickGotIt = () => {
        this.setState({ drawStep1: false, });

        setTimeout(() => {
            let paths = localStorage.getItem(`${this.state.myId}-CanvasPaths`);
            if (paths != null) {
                paths = JSON.parse(paths);

                if (this.canvas.current) {
                    this.canvas.current.loadPaths(paths);
                }
            }
        }, 100);
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    doReconnect = () => {
        // fetch room and session id from query params
        const roomId = this.getQueryStringValue("roomId");
        const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

            room.onStateChange.once(async (state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                this.startLocationChecks();

                const drawingURL = player.drawWithData.drawingURL;
                const isDave = player.id == state.drawWithData.currentDaveId;
                const hasGuessed = player.drawWithData.hasGuessed;
                const players = this.getArrayOfPlayers(state.players);
                this.setState({
                    roomState: state,
                    player,
                    isPaused: state.isPaused,
                    drawingURL,
                    isDave,
                    hasGuessed,
                    players,
                });

                await this.doCatchup(state);

                room.state.drawWithData.listen("gameState", (currentValue, previousValue) => {
                    console.log(`gameState change detected : ${currentValue}`);
                    this.setState({ gameState: currentValue });
                });
            });
            room.onStateChange(async (state) => {
                console.log("room has new state:", state);
                const player = state.players[room.sessionId];
                const drawingURL = player.drawWithData.drawingURL;
                const isDave = player.id == state.drawWithData.currentDaveId;
                const hasGuessed = player.drawWithData.hasGuessed;
                const players = this.getArrayOfPlayers(state.players);
                this.setState({
                    roomState: state,
                    player,
                    drawingURL,
                    isDave,
                    hasGuessed,
                    players,
                });
                await this.doCatchup(state);
            });


            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: true, showDefaultView: false, });
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: false, showDefaultView: true, });
            });
            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });
            room.onMessage("location_confirmed", (message) => {
                console.log("location_confirmed", "received on", room.name, message);
                this.setState({ gotLocationPing: true, });
            });

            // your game message handlers here
            room.onMessage("start_round", (message) => {
                console.log("start_round", "received on", room.name, message);
                if (message.nextDave.id == this.state.myId) {
                    this.setState({ isDave: true, drawStep1: true, });
                }
            });
            room.onMessage("start_drawing", async (message) => {
                console.log("start_drawing", "received on", room.name, message);
                await this.startDrawingRound(message);
            });
            room.onMessage("force_submit_drawings", (message) => {
                console.log("force_submit_drawings", "received on", room.name, message);
                if (this.state.showDrawingSection && !this.state.isDave) {
                    this.submitDrawing();
                }
            });
            room.onMessage("force_dave_submit", (message) => {
                console.log("force_dave_submit", "received on", room.name, message);
                if (this.state.isDave && this.state.showDrawingSection) {
                    this.submitDrawing();
                }
            });
            room.onMessage("force_submit", (message) => {
                console.log("force_submit", "received on", room.name, message);
                this.setState({ showDefaultView: true, showGuessSection: false, showChoosingSection: false, });
            });
            room.onMessage("start_early_guess_timer", (message) => {
                console.log("start_early_guess_timer", "received on", room.name, message);
                if (!this.state.isDave) {
                    this.setState({ showDefaultView: false, showDrawingSection: false, showGuessSection: true, isEarlyGuess: true, });
                }
            });
            room.onMessage("start_final_guess_timer", (message) => {
                console.log("start_final_guess_timer", "received on", room.name, message);
                if (!this.state.isDave && !this.state.hasGuessed) {
                    this.setState({ showDefaultView: false, showDrawingSection: false, showGuessSection: true, });
                }
            });
            room.onMessage("start_choosing", (message) => {
                console.log("start_choosing", "received on", room.name, message);
                if (this.state.isDave) {
                    this.setState({ showDefaultView: false, showChoosingSection: true,  });
                }
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId != gameId) {
                    this.goToLobby();
                }
            });
            room.onMessage("catchup", async (message) => {
                console.log("catchup", "received on", room.name, message);
                if (message.data.id == this.state.myId) {
                    await this.doCatchup(message.gameState, message.data);
                }
            });
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
                //LoggingService.logError(message, code);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);

                if (!this.state.redirectURL) {
                    if (code == 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            const message = e.message ? e.message : "An error occured joining Draw with Dave.";
            //LoggingService.logError(message, e);
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={styles.clientContainer}>
                            {
                                this.state.connectionIssue &&
                                <div className={styles.connectionIssueContainer}>
                                    <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                    <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                </div>
                            }
                            {
                                this.state.showDefaultView &&
                                <DefaultView room={this.state.room} player={this.state.player} isDave={this.state.isDave} hostConnected={this.state.hostConnected} players={this.state.players} />
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.state.doingTutorial &&
                                <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                    <div className={styles.skipButton}>Skip Tutorial</div>
                                </div>
                            }
                            {
                                (this.state.showDrawingSection || this.state.isSubmitting) &&
                                <div className={styles.drawingSection}>
                                    {
                                        this.state.drawStep1 && this.state.isDave ?
                                            <React.Fragment>
                                                <div className={styles.genericTitle}>Dave, draw a:</div>
                                                <div className={`${styles.genericTitle} ${styles.yellow}`}>{this.state.currentQuestion}</div>
                                                <div className={`${styles.submitButton}`} onClick={() => this.clickGotIt()}>Got it!</div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className={styles.drawingContainer}>
                                                        {/*<img src={easel} className={styles.easelBg} />*/}
                                                        {
                                                            this.state.isDave &&
                                                            <div className={`${styles.genericTitle} ${styles.yellow} ${styles.smaller}`}>{this.state.currentQuestion}</div>
                                                        }
                                                    <div className={`${styles.questionText}`}>{this.state.isDave ? "Describe as you draw" : "Draw along with Dave!"}</div>
                                                    <ReactSketchCanvas
                                                        ref={this.canvas}
                                                        className={styles.drawCanvas}
                                                        width={this.state.canvasWidth}
                                                        height={this.state.canvasWidth}
                                                        strokeWidth={this.state.currenPenSize}
                                                        strokeColor="black"
                                                        canvasColor="#F1F1F1"
                                                        eraserWidth={this.state.currenPenSize}
                                                    />
                                                </div>
                                                <div className={styles.toolsContainer}>
                                                    <div className={styles.toolsInner}>
                                                        <div className={styles.chosenPen} onClick={this.showPens}>
                                                            <div className={styles.innerPen} style={{ width: `${this.state.currenPenSize}px`, height: `${this.state.currenPenSize}px`, }}></div>
                                                        </div>
                                                        <img src={EraserImg} className={`${styles.tool} ${this.state.eraseMode ? styles.toggled : ""}`} onClick={this.toggleErase} />
                                                        <img src={PenImg} className={`${styles.tool} ${this.state.eraseMode ? "" : styles.toggled}`} onClick={this.toggleErase} />
                                                        <img src={UndoImg} className={`${styles.tool}`} onClick={this.undoDraw} />
                                                        <img src={UndoImg} className={`${styles.tool} ${styles.flipped}`} onClick={this.redoDraw} />
                                                    </div>
                                                    <div className={`${styles.penSizeSection} ${this.state.showPens ? styles.show : ""}`}>
                                                        {
                                                            this.state.penSizes.map((x) => {
                                                                return <div className={`${styles.penContainer} ${this.state.penSize == x ? styles.show : ""}`} onClick={() => this.handlePenSizeChange(x)}>
                                                                    <div
                                                                        className={styles.penSize}
                                                                        style={{ width: `${x}px`, height: `${x}px`, }}
                                                                    >
                                                                        <div className={`${styles.innerCircle} ${this.state.penSize == x ? styles.show : ""}`} />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <button onClick={this.clearCanvas} className={`${styles.submitButton} ${styles.smaller}`}>Clear Canvas</button>
                                                {
                                                    this.state.isDave &&
                                                    <button onClick={this.submitDrawing} className={`${styles.submitButton} ${styles.smaller}`}>Finished</button>
                                                }
                                            </React.Fragment>
                                    }
                                </div>
                            }
                            {
                                this.state.showGuessSection &&
                                <div className={styles.guessSection}>
                                    <div className={`${styles.genericTitle} ${styles.fancy}`}>What did Dave draw?</div>
                                    {
                                        this.state.isEarlyGuess &&
                                        <img src={this.state.drawingURL} className={styles.guessDrawing} />
                                    }
                                    <input type="text" maxLength={30} onChange={this.updateGuess} placeholder="Guess here..." value={this.state.currentGuess} className={styles.guessInput} />
                                    <div className={styles.guessButtonsBox}>
                                        <button onClick={this.submitGuess} className={styles.submitButton}>Submit ({this.state.isEarlyGuess ? 3 : 1})</button>
                                        {
                                            this.state.isEarlyGuess &&
                                            <button onClick={this.guessLater} className={`${styles.submitButton} ${styles.smaller}`}>Guess Later (1)</button>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.showChoosingSection &&
                                <div className={styles.choosingSection}>
                                    <div className={styles.genericTitle}>Who was <br/> correct?</div>
                                    <div className={styles.choiceList}>
                                        {
                                            this.state.players.map((player) => {
                                                if (!player.drawWithData.isDave) {
                                                    return <div className={`${styles.choice} ${this.state.choiceList.includes(player.id) && styles.selected}`} onClick={() => this.selectChoice(player.id)}>
                                                        {player.drawWithData.currentGuess}
                                                    </div>
                                                }
                                                
                                            })
                                        }
                                    </div>
                                    <button onClick={this.submitChoices} className={styles.submitButton}>Submit</button>
                                </div>
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
